import { SpinnerService } from '@app/estimates/services/spinner.service';
import { ApiTypes } from '@app/shared/constants/constant';
import { Injectable } from '@angular/core';
import { ServerConnectService } from '@app/core/services/server-connect.service';
import { CommonService } from '@app/shared/services/common.service';
import { queryParams } from '@app/shared/utils/query-params';
import {
  NFPURI,
  ScheduleURI,
} from '@app/trips/schedule-creation/schedule-config-url';
import { BehaviorSubject } from 'rxjs';
import { CharterLegInformationDeatils } from '../model/estimate.model';

@Injectable({
  providedIn: 'root',
})
export class CharterQuoteService {
  public apiType = ApiTypes;
  public $legListDetails = new BehaviorSubject<any>([]);
  public legIndex: any = +1;
  public shortData: any;
  public routeWarnings: boolean = false;
  public routeWarningValues: any;
  public Etops: string = 'yes';
  public newArray: any;
  constructor(
    private commonService: CommonService,
    private serverConnectService: ServerConnectService,
    private spinnerService:SpinnerService
  ) { }

  public getRoute(leglistDetails, legDetails) {
    const tempArray = [];
    const storeArray = [];
    return new Promise((resolve, reject) => {
      let plus = 0;
      leglistDetails.forEach((legData, index, array) => {
        const charterLegData = legData;
        this.legIndex = legData.legId;
        legData = JSON.stringify(legData);
        const databody = queryParams({
          aircraftID: legDetails.AircraftID,
          legIndex: this.legIndex,
          legData,
        });
        this.serverConnectService
          .connectToServer(
            this.apiType.POST,
            NFPURI.GenerateRoute,
            databody,
            false,
            false,
            false,
            'api/v1'
          )
          .then(async (routeData: any) => {
            plus++;
            // need to handel the response
            this.spinnerService.hide();
            if (routeData.GetRouteDetails?.error != null) {
              // this.commonService.dismissLoader();
              if (plus == leglistDetails.length) {
                await this.commonService.dismissLoader();
                this.commonService.showMeassgeAlert(
                  routeData.GetRouteDetails.error
                ).then(async (res) => {
                await this.commonService.dismissLoader();
                })

              }
            } else {
              const receiveCompute = routeData.GetRouteDetails;
              charterLegData.BLKTIME = receiveCompute.EstFlightTime;
              charterLegData.DIST = receiveCompute.timezonedetails.dist;
              charterLegData.ETDGMT = receiveCompute.ETDGMT;
              charterLegData.ETDLT = receiveCompute.ETD;
              charterLegData.ETAGMT = receiveCompute.ETAGMT;
              charterLegData.ETALT = receiveCompute.ETA;
              charterLegData.etops = 'yes';
              charterLegData.country = receiveCompute.countryList;
              charterLegData.aircraft =
                receiveCompute.AircraftDetail.AircraftID;
              charterLegData.legRouteid = receiveCompute.routeId;
              charterLegData.timezonedetails = receiveCompute.timezonedetails;
              storeArray.push(charterLegData);
              if (plus == leglistDetails.length) {
                this.resultHold(storeArray, resolve);
              }
            }
            // to display shornotice alert
            if (routeData) {
              this.shortData = routeData.GetRouteDetails.PermitsLeadData;
              this.routeWarnings = true;
              this.routeWarningValues = this.shortData;
            }
            if (routeData && routeData.GetRouteDetails?.error != null) {
              this.commonService.dissmissSpinnerLoader();
            }
          })
          .catch(async (err: any) => {
            await this.commonService.dismissLoader();
            this.spinnerService.hide();
            this.commonService.showErrorAlert().then((option: boolean) => {
              if (option) {
                // this.getRoute(legId, leg);
              }
            });
          });
      });
    });
  }
  public async resultHold(data, resolve) {
    resolve(data);
  }

  public getNewQuote(result) {
    const getNewQoute = queryParams({
      vendorsArr: JSON.stringify({ LegList: result }),
    });
    return new Promise((resolve, reject) => {
      this.serverConnectService
        .connectToServer(
          this.apiType.POST,
          ScheduleURI.GetPreferedVendorAddedInTrip,
          getNewQoute,
          false,
          false,
          false
        )
        .then(
          (responseData) => {
            resolve(responseData);
          },
        ).catch(async (err) => {
          await this.commonService.dismissLoader();
          this.spinnerService.hide();
          this.commonService.showErrorAlert();
          reject(false);
        });
    });
  }

  public getCharterDraftTripByID(result) {
    const getNewQoute = queryParams({
      trip_id: result ,
    });
    return new Promise((resolve, reject) => {
      this.serverConnectService
        .connectToServer(
          this.apiType.POST,
          ScheduleURI.getCharterDraftTripByID,
          getNewQoute,
          false,
          false,
          false
        )
        .then(
          (responseData) => {
            resolve(responseData);
          },
        ).catch((err) => {
          this.commonService.dismissLoader();
          this.commonService.showErrorAlert();
          reject(false);
        });
    });
  }
  public cancelTripByCharter(srid, status) {
    return new Promise((resolve) =>{
      const cancelTripData = 'enct=1&srid=' + srid + '&type=' + status;
      this.serverConnectService
        .connectToServer(
          this.apiType.POST,
          ScheduleURI.cancelCharterEstimate,
          cancelTripData,
          false,
          false,
          false
        )
        .then((data: any) => {
          resolve(data.ResponseStatus);
        })
        .catch((err) => {
          resolve('Error');
        });

    })
  }
  public cancelEstimateTripByCharter(srid, status) {
    return new Promise((resolve) =>{
      const cancelTripData = 'enct=1&srid=' + srid + '&type=' + status;
      this.serverConnectService
        .connectToServer(
          this.apiType.POST,
          ScheduleURI.cancelCharterEstimate,
          cancelTripData,
          false,
          false,
          false
        )
        .then((data: any) => {
          resolve(data.ResponseStatus);
        })
        .catch((err) => {
          resolve('Error');
        });

    })
  }
}
